export class Deferred<T = any, E = any> {

    private _resolve;
    private _reject;

    constructor() {

        this.promise = new Promise((res, rej) => {

            this._resolve = res;
            this._reject = rej;
        });
    }

    promise: Promise<T>;

    resolve(result?: T): void {

        this._resolve(result);
    }

    reject(error?: E): void {

        this._reject(error);
    }
}
