import * as E from "linq";
import { Injectable } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";
import { IdGeneratorService } from "ui-devkit";
import { Deferred } from "@Utils/Deferred";
import { ToasterConfig as Config } from "./toaster.config";

export enum ToastType {
    SUCCESS = "success",
    INFO = "info",
    WARNING = "warning",
    ERROR = "error",
}

type ToastInner = {

    action?(): void;
    message?: string;
    actionTitle?: string;
};

export type Toast = {

    type: ToastType;
    title: ToastInner;
    body?: ToastInner;
    html?: SafeHtml;
    timeout?: number;
    hideClose?: boolean;
    id?: number;
    deferred?: Deferred<Toast>;
    iconClass?: string;
    [key: string]: any;
};

@Injectable({ providedIn: "root" })
export class ToasterBackendService {

    constructor(
        private idGenerator: IdGeneratorService,
    ) {}

    private toastList: Array<Toast> = [];
    get activeToasts() { return this.toastList as Array<Readonly<Toast>>; }

    pop(type: ToastType, title: string, body?: string) {

        this.addToast({
            type,
            title: title ? { message: title } : null,
            body: body ? { message: body } : null,
        });
    }

    popWithPromise(toast: Toast): Promise<any> {

        const deferred = new Deferred<Toast>();
        toast.deferred = deferred;
        this.addToast(toast);
        return deferred.promise;
    }

    popWithUndo(params: { undo(): void; message: string }): void {

        this.addToast({
            type: ToastType.SUCCESS,
            timeout: 0,
            title: { actionTitle: "undo", action: params.undo, message: params.message },
            body: null,
        });
    }

    clear(predicate?: (i: Toast) => boolean): void {

        this.toastList = E.from(this.toastList)
            .where(i => {
                return predicate ? !predicate(i) : false;
            })
            .toArray();
    }

    configureTimer(toast: Toast) {

        const timeout = typeof (toast.timeout) == "number" ? toast.timeout : Config.timeOut;

        if (timeout > 0) {

            toast.timeout = setTimeout(() => { this.remove(toast.id); }, timeout);
        }
    }

    remove(id: number) {

        this.toastList = E.from(this.toastList)
            .where(i => i.id != id)
            .toArray();
    }

    private addToast(toast: Toast) {

        toast.iconClass = Config.iconClasses[toast.type] ?? Config.defaultIcon;
        toast.id = this.idGenerator.getUniqueNumericId();

        this.configureTimer(toast);

        if (Config.newestOnTop) {

            this.toastList.unshift(toast);

            if (Config.limit > 0 && this.toastList.length > Config.limit) {

                this.toastList.pop();
            }
        }
        else {

            this.toastList.push(toast);

            if (Config.limit > 0 && this.toastList.length > Config.limit) {

                this.toastList.shift();
            }
        }
    }
}
